import React from 'react';
import loadable from '@loadable/component';
import { LayoutSplashScreen, ContentRoute } from '@metronic/layout';

const PatientManagementList = loadable(
  () =>
    import(
      /* webpackChunkName: "patient-management-list" */ '@routes/PatientManagement/pages/PatientList/'
    ),
  {
    ssr: true,
    fallback: <LayoutSplashScreen />
  }
);
const PatientManagementCreate = loadable(
  () =>
    import(
      /* webpackChunkName: "patient-management-create" */ '@routes/PatientManagement/pages/NewPatient/'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);
const PatientManagementDetail = loadable(
  () =>
    import(
      /* webpackChunkName: "patient-management-update" */ '@routes/PatientManagement/pages/PatientDetail'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);
const PatientManagementEdit = loadable(
  () =>
    import(
      /* webpackChunkName: "patient-management-edit" */ '@routes/PatientManagement/pages/EditPatient/'
    ),
  {
    fallback: <LayoutSplashScreen />
  }
);
export default () => {
  return (
    <>
      <ContentRoute
        exact
        path="/patient-management"
        permission={['Admin', 'Coach']}
        component={PatientManagementList}
      />
      <ContentRoute
        exact
        path="/patient-management/create"
        permission={['Admin']}
        component={PatientManagementCreate}
      />
      <ContentRoute
        exact
        path="/patient-management/detail/:program/:id"
        permission={['Admin', 'Coach']}
        component={PatientManagementDetail}
      />
      <ContentRoute
        exact
        path="/patient-management/edit/:program/:id"
        permission={['Admin', 'Coach']}
        component={PatientManagementEdit}
      />
    </>
  );
};
